<template>
  <div :class="$vuetify.breakpoint.xs ? 'mx-4 mt-4' : 'mx-8 mt-8'">
    <SalesTable :isUserAdmin="isAdmin" :isUserSeller="isSeller"/>
  </div>
</template>

<script>
import SalesTable from '@/components/sales/SalesTable'
import { mapGetters } from 'vuex'

export default {
  name: 'Sales',

  data: () => ({
    isAdmin: false,
    isSeller: false
  }),

  components: {
    SalesTable
  },

  mounted () {
    if (this.userRoles) {
      this.userRoles.some(r => r.id === 1)
        ? this.isAdmin = true
        : this.isAdmin = false
      this.userRoles.some(r => r.id === 3)
        ? this.isSeller = true
        : this.isSeller = false
    }
  },

  computed: {
    ...mapGetters(['userData', 'userRoles'])
  },

  watch: {
    userRoles: {
      handler () {
        if (this.userRoles) {
          this.userRoles.some(r => r.id === 1)
            ? this.isAdmin = true
            : this.isAdmin = false
          this.userRoles.some(r => r.id === 3)
            ? this.isSeller = true
            : this.isSeller = false
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
